<template lang="pug">
  .page-container.driving_schools
    portal(to="toolbar-title")
      toolbar-title Driving Schools

    portal(to="toolbar-end")
      driving-school-crud(@needToUpdate="loadList")
        template(v-slot:activator="schoolCrud")
          app-button(hide-shadow @click.native="schoolCrud.toggle").head-btn Add
      app-button(@click.native="getReport" hide-shadow :disabled="loading").head-btn.ml-2 Report

    .page-container__block
      search-widget(@change="loadList")

    .page-container__block
      filters(:loading="loading" @set:filter="setFilter" @reset:filter="resetFilter")

    .page-container__block.border-b
      .d-flex
        tabs(@change="loadList")
      .d-flex
        portal-target(name="ds-pagination")

    .page-container__list
      transition(name="fade-up")
        router-view(ref="list")
</template>

<script>
import { DrivingSchoolService } from './core/drivingSchool-service'
import ErrorMixin from '@/mixins/errors.mixin'
import paginationMixin from '@/mixins/pagination.mixin'
import { downloadFile } from '@/util/index.js'
import DrivingSchoolCrud from './components/DrivingSchoolCRUD.vue'
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  name: 'DrivingSchoolPage',

  mixins: [paginationMixin, ErrorMixin],

  data: () => ({
    loading: false
  }),

  methods: {
    async loadList() {
      await this.$refs.list.loadList({})
    },

    async getReport() {
      this.loading = true;
      try {
        let response = await new DrivingSchoolService().report_csv(this.$route.query)
        downloadFile(response, 'report.csv')
      } catch (err) {
        this.processError(err);
      } finally {
        this.loading = false;
      }
    },

    async setFilter(type, payload, load = true) {
      if (this.loading) return
      if (this.$route.query[type] == payload) return

      if (!payload) return await this.setQuery({[type]: undefined}, load)

      try {
        this.loading = true
        await this.setQuery({[type]: payload}, load);
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    resetFilter(type) {
      if (this.loading) return
      if (!this.$route.query[type]) return
      this.setFilter([type], undefined)
    },

    async setQuery(data, load) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...data}})
      if (load) await this.loadList()
    },
  },

  components: {
    appButton,
    DrivingSchoolCrud,
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    searchWidget: () => import('@/components/widget/SearchWidget.vue'),
    tabs: () => import('./components/DrivingSchoolTabs.vue'),
    filters: () => import('./components/DrivingSchoolFilters.vue')
  }
}
</script>